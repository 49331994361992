import React, { useCallback, useEffect, useState } from "react";
import "../Styles/Fees.css";
import { getDataFromCollection } from "../utils/helper";
import { COLLECTIONS } from "../utils/constants";

const Fees = () => {
	const [data, setData] = useState([]);
	const [frcLink, setFrcLink] = useState("");

	const fetchData = useCallback(async () => {
		try {
			const feesData = await getDataFromCollection(COLLECTIONS.FEES);
			setData(feesData);
		} catch (error) {
			console.log("error in fetchData	", error);
		}
	}, []);

	const fetchFrcData = useCallback(async () => {
		try {
			const frcData = await getDataFromCollection(COLLECTIONS.FRC_ORDER);
			if (frcData.length > 0) {
				const url = frcData[0].pdf.url;
				console.log("url :>> ", url);
				setFrcLink(url);
			}
		} catch (error) {
			console.log("error in fetchFrcData", error);
		}
	}, []);

	useEffect(() => {
		fetchData();
		fetchFrcData();
	}, [fetchData, fetchFrcData]);

	return (
		<div className="fees-structure">
			<div className="row mr-0">
				<div className="col">
					<h4 className="fees-content">Fee Structure for 2024-25</h4>
				</div>
				<div className="row mr-0"></div>
				<div className="col-12">
					<h6 className="fess-data">Pre Primary to Class XII</h6>
				</div>
			</div>
			<div className="col-12">
				<table className=" table-bordered fees-table">
					<thead>
						<tr>
							<th>Class</th>
							<th>Annual Fees</th>
							<th>Admission Fees</th>
						</tr>
					</thead>
					<tbody>
						{data.map((item) => (
							<tr key={item.id}>
								<td>{item.stdName}</td>
								<td>{item.annualFees}</td>
								<td>{item.admissionFees}</td>
							</tr>
						))}
					</tbody>
				</table>
				<p className="Notes">Note:</p>
				<p className="note">
					1. Above mention fees is as per the order of Fee Regulatory Committee
					(FRC) dated 2-05-2024.
				</p>
				<div className="FRC">
					<li>
						FRC Order:{" "}
						<a href={frcLink} target="_blank" rel="noreferrer">
							Click Here
						</a>
					</li>
				</div>
			</div>
		</div>
	);
};
export default Fees;
