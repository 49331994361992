import React, { useCallback, useEffect, useState } from "react";

import "../Styles/OurSchool.css";
import img2 from "../Images/img1.png";

import ourVision from "../Images/OurVision.png";
import { getDataFromCollection } from "../utils/helper";
import { COLLECTIONS } from "../utils/constants";
import GroupedResults from "./GroupedResults";

function OurSchool() {
	const [sliderData, setSliderData] = useState({});

	const fetchSliderImagesData = useCallback(async () => {
		try {
			const data = await getDataFromCollection(COLLECTIONS.RANK);
			console.log("fetchSliderImagesData", data);

			const groupedData = data.reduce((acc, item) => {
				const year = item.year;
				const className = item.class;

				if (!acc[year]) {
					acc[year] = {};
				}

				if (!acc[year][className]) {
					acc[year][className] = {};
				}

				if (className === "12") {
					const stream = item.stream;
					if (!acc[year][className][stream]) {
						acc[year][className][stream] = [];
					}
					acc[year][className][stream].push(item);
				} else if (className === "10") {
					if (!acc[year][className].students) {
						acc[year][className].students = [];
					}
					acc[year][className].students.push(item);
				}

				return acc;
			}, {});

			console.log("groupedData", groupedData);

			setSliderData(groupedData);
		} catch (error) {
			console.log("Error in fetchSliderImagesData", error);
		}
	}, []);

	useEffect(() => {
		fetchSliderImagesData();
	}, [fetchSliderImagesData]);

	return (
		<div className="OurSchool">
			<div className="main mr-0 align-items-center justify-content-center">
				<div className="about-school">
					<h3 className="School-Para">
						Modern Public School, <br /> Najibabad
					</h3>
					<p>
						Modern Public School, Najibabad is a member of MPS Education
						Society, a non-profit educational body , recognized for its
						progressive approach and commitment to excellence. The society
						started its first school in 1989 and since then has built upon its
						existing standards standard's of imparting excellent education. The
						school offers education from Class Nursery to Class XII with all the
						<br /> three streams including Science , Commerce and Humanities.
					</p>
					<p>
						Affiliated to C.B.S.E. (New Delhi) our co-educational English medium
						school operates from three campuses: <br />
						<p
							style={{
								fontWeight: "bold",
							}}
						>
							MPS , Ajmal Khan Road. <br />
							MPS , Kotdwara Road. <br />
							MPS , Govind Nagar.
						</p>
					</p>
					<p>
						Since 1989, MPS has crafted, executed, and propagated a specialized
						user-centric curriculum, offering schools an alternative framework
						that emphasizes learning excellence and student welfare.
					</p>
				</div>
				<div>
					<img src={img2} alt="Assembly Room" />
				</div>
			</div>
			<div className="ResultContainer row mr-0">
				<div className="result mr-0"></div>
				<div
					style={{
						width: "100%",
						height: "100vh",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						overflow: "hidden",
					}}
				>
					<GroupedResults groupedData={sliderData} />
				</div>
				<div />
			</div>
			<div className="image">
				<img src={ourVision} alt="" />
			</div>
			<div
				className="objectives"
				style={{
					marginTop: "5rem",
				}}
			>
				<h3>Our Objectives</h3>
			</div>

			<div className="row mr-0">
				<div className="informationSchool">
					<p className="info1">
						To provide a unique learning environment to the children and impart
						the best of knowledge and values in life.
					</p>
					<p className="info2">
						To help students attain skills and attitude which will help them to
						face the challenges of the outside world.
					</p>
					<p className="info3">
						To create a stress free environment where students love to learn
					</p>
				</div>
			</div>
		</div>
	);
}

export default OurSchool;
