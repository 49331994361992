import React from "react";
import HeaderBanner from "../Components/HeaderBanner";
import Bg5 from "../Images/bg5.png";
import UprightArrow from "../Images/up-right.png";
import OurSchool from "../Components/OurSchool";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import { TEXTS } from "../utils/constants";

const Home = () => {
	const navigate = useNavigate();
	return (
		<div className="overflow-hidden">
			<HeaderBanner
				bannerImage={Bg5}
				showLogo
				singleLine1={
					"Celebrating educational excellence and greatness since 1989"
				}
				bannerTitle1={"Welcome To Modern Public School "}
				singleLine2={`Affiliated to CBSE Delhi, Affiliation Number : ${TEXTS.AFFILIATION_NUMBER}`}
				buttonText={`Apply For Admission`}
				buttonLogo={UprightArrow}
				buttonClick={() => navigate("/inquiry")}
			/>

			<OurSchool />

			<Footer />
		</div>
	);
};

export default Home;
