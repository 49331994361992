import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../utils/firebaseConfig";
import Home from "../Pages/Home";
import SubjectOffer from "../Pages/SubjectOffer";
import AdmissionProcedure from "../Pages/AdmissionProcedure";
import LcTC from "../Pages/LcTc";
import Inquiry from "../Pages/Inquiry";
import TransferCertificate from "../Pages/TransferCertificate";
import FeeStructure from "../Pages/FeeStructure";
import MandatoryDisclosure from "../Pages/MandatoryDisclosure";
import CbseCircular from "../Pages/CbseCircular";
import Contact from "../Pages/Contact";
import StaffDetails from "../Pages/StaffDetails";
import Infrastructure from "../Pages/Infrastructure";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Home />,
	},
	{
		path: "/subjectOffer",
		element: <SubjectOffer />,
	},
	{
		path: "/admissionProcedure",
		element: <AdmissionProcedure />,
	},
	{
		path: "/inquiry",
		element: <Inquiry />,
	},
	{
		path: "/lcTC",
		element: <LcTC />,
	},
	{
		path: "/transferCertificate",
		element: <TransferCertificate />,
	},
	{
		path: "/feeStructure",
		element: <FeeStructure />,
	},
	{
		path: "/mandatoryDisclosure",
		element: <MandatoryDisclosure />,
	},
	{
		path: "/cbseCircular",
		element: <CbseCircular />,
	},
	{
		path: "/Contact",
		element: <Contact />,
	},
	{
		path: "/StaffDetails",
		element: <StaffDetails />,
	},
	{
		path: "/Infrastructure",
		element: <Infrastructure />,
	},
]);

const Main = () => {
	return (
		<>
			<RouterProvider router={router}></RouterProvider>
		</>
	);
};

export default Main;
