import React, { useState } from "react";
import "./Accordion.css";

const renderTextWithNewlines = (text) => {
	return text.split("\n").map((line, index) => <p key={index}>{line}</p>);
};

const Accordion = ({ data }) => {
	const [activeIndex, setActiveIndex] = useState(null);

	const toggleAccordion = (index) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	return (
		<div>
			{data.map((item, index) => (
				<div key={index}>
					<button
						className={`accordion ${activeIndex === index ? "active" : ""}`}
						onClick={() => toggleAccordion(index)}
					>
						<span>Standard {item.standard}</span>
						<div className={`panel ${activeIndex === index ? "open" : ""}`}>
							<h3 className="text-center mt-3 mb-4">
								Class {item.standard} ({item.year})
							</h3>
							{renderTextWithNewlines(item.subjectOffer)}
						</div>
					</button>
				</div>
			))}
		</div>
	);
};

export default Accordion;
