import React from "react";
import HeaderBanner from "../Components/HeaderBanner";
import Footer from "../Components/Footer";
import AdmissionForm from "../Components/AdmissionForm";
import Bg1 from "../Images/bg1.png";
import UprightArrow from "../Images/up-right.png";

const Inquiry = () => {
	return (
		<div>
			<HeaderBanner
				bannerImage={Bg1}
				logoText={"Modern Public School"}
				subTitle={"Registration For Inquiry"}
				buttonText={`Apply For Admission`}
				buttonLogo={UprightArrow}
			/>

			<AdmissionForm />

			<Footer />
		</div>
	);
};

export default Inquiry;
