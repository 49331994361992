import React, { useState } from "react";
import "../Styles/HeaderBanner.css";
import img1 from "../Images/logo1.png";

const HeaderBanner = ({
	bannerImage,
	bannerTitle1,
	subTitle,
	singleLine1,
	singleLine2,
	buttonText,
	buttonLogo,
	buttonClick,
}) => {
	const [scroll, setScroll] = useState(0);

	window.addEventListener("scroll", () => {
		setScroll(window.scrollY);
	});

	return (
		<div
			className="header-banner banner"
			style={{
				height: "100vh",
				position: "relative",
				backgroundImage: `url(${bannerImage})`,
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
			}}
		>
			<div
				style={{
					position: "absolute",
					top: 0,
					height: "100vh",
					left: 0,
					width: "100%",
					background: "rgba(0,0,0, 0.30)",
					opacity: 0.4,
				}}
			/>

			{scroll > 100 && (
				<div
					style={{
						height: "20vh",
					}}
				/>
			)}

			<div
				className="row mr-0"
				style={{
					position: scroll > 100 ? "fixed" : "relative",
					top: 0,
					left: 0,
					zIndex: 99,
					transition: "all 0.5s",
					background: scroll > 100 ? "#212121" : "transparent",
					height: "20vh",
					width: "101vw",
				}}
			>
				<div className="col-2 logo">
					<img
						src={img1}
						alt=""
						style={{
							width: "40%",
							// height: "10%",
						}}
					/>
				</div>
				<div className="col-8">
					<div className="navbar">
						<ul className="mb-0">
							<li className="main_li">
								<a href="/">Our School</a>
							</li>
							<li className="Academics-btn">
								<a href="/subjectOffer" className="dropdown-toggle">
									Academics
								</a>
								<ul className="dropdown subject-dropdown">
									<li>
										<a href="/subjectOffer">Syllabus Offered</a>
									</li>
								</ul>
							</li>

							<li className="Admission-Buttons">
								<a href="/admissionProcedure" className="dropdown-toggle">
									Admission
								</a>
								<ul className="dropdown Admission-dropdown">
									<li>
										<a href="/admissionProcedure">Admission Procedure</a>
									</li>
									<li>
										<a href="/inquiry">Registration For Inquiry</a>
									</li>
									<li>
										<a href="/lcTC">Procedure for TC-LC</a>
									</li>
									<li>
										<a href="/transferCertificate">
											Download Transfer Certificate
										</a>
									</li>
									<li>
										<a href="/feeStructure">Fees Structure</a>
									</li>
								</ul>
							</li>

							<li className="main_li">
								<a href="/Infrastructure">Facilities</a>
							</li>
							<li className="main_li">
								<a href="/contact">Contact Us</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="login">
					<a
						href="https://admin.mpsnajibabad.in/"
						target="_blank"
						rel="noreferrer"
						className="button"
						style={{
							background: "transparent",
							color: "#fff",
						}}
					>
						Login
						<img src={buttonLogo} alt="" />
					</a>
				</div>
			</div>
			{singleLine1 && (
				<div
					className="s-line"
					style={{
						width: "fit-content",
					}}
				>
					<p>{singleLine1}</p>
				</div>
			)}

			<div
				className="container heading"
				style={{
					zIndex: 1,
					position: "relative",
				}}
			>
				<h1
					className="heading1"
					style={{
						width: "80%",
					}}
				>
					{bannerTitle1}
				</h1>
				<h1
					className="heading2"
					style={{
						marginTop: bannerTitle1 ? "0rem" : "13rem",
					}}
				>
					{subTitle}
				</h1>
			</div>
			<div
				className="container heading"
				style={{
					zIndex: 1,
					position: "relative",
				}}
			>
				<div className=" s-line2">{singleLine2}</div>
				{buttonText && buttonLogo && (
					<button
						className="btn admission"
						onClick={(e) => {
							e.preventDefault();
							buttonClick && buttonClick();
						}}
					>
						{buttonText}
						<img src={buttonLogo} alt="" />
					</button>
				)}
			</div>
		</div>
	);
};

export default HeaderBanner;
