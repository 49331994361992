import React, { useEffect, useState } from "react";
import HeaderBanner from "../Components/HeaderBanner";
import Footer from "../Components/Footer";
import Bg1 from "../Images/bg7.png";
import UprightArrow from "../Images/up-right.png";
import Documents from "../Components/Documents";
import { getDataFromCollection } from "../utils/helper";
import { COLLECTIONS } from "../utils/constants";

const MandatoryDisclosure = () => {
	const [listData, setListData] = useState([]);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		try {
			const data = await getDataFromCollection(
				COLLECTIONS.MANDATORY_DISCLOSURE
			);
			console.log("Data", data);
			setListData(data);
		} catch (error) {
			console.log("Error in getData", error);
		}
	};

	return (
		<div>
			<HeaderBanner
				bannerImage={Bg1}
				logoText={"Modern Public School"}
				subTitle={"Mandatory Disclosure"}
				buttonLogo={UprightArrow}
			/>
			<Documents
				data={listData}
				description={`As part of our commitment to transparency and compliance, MPS, Najibabad provides the mandatory disclosures as stipulated by the Central Board of Secondary Education (CBSE). This information is crucial for parents, students, and stakeholders to understand our adherence to regulatory guidelines and educational standards.`}
			/>
			<Footer />
		</div>
	);
};
export default MandatoryDisclosure;
