import React from "react";
import HeaderBanner from "../Components/HeaderBanner";
import Footer from "../Components/Footer";
import Bg1 from "../Images/bg6.png";
import UprightArrow from "../Images/up-right.png";
import Images from "../Components/Images";

const Infrastructure = () => {
	return (
		<div>
			<HeaderBanner
				bannerImage={Bg1}
				logoText={"Modern Public School"}
				subTitle={"Infrastructure"}
				buttonLogo={UprightArrow}
			/>
			<Images />
			<Footer />
		</div>
	);
};
export default Infrastructure;
