import React from "react";
import "../Styles/StaffList.css";
import { getDataFromCollection } from "../utils/helper";
import { COLLECTIONS } from "../utils/constants";

const StaffList = () => {
	const onButtonClick = async () => {
		try {
			const data = await getDataFromCollection(COLLECTIONS.STAFF_LIST);

			if (data.length > 0) {
				const url = data[0].pdf.url;
				window.open(url, "_blank");
			}
		} catch (error) {
			console.log("Error: ", error);
		}
	};

	return (
		<div className="container">
			<div className="row mr-0">
				<div className="col">
					<h1>Staff List</h1>
					<p>
						Welcome to MPS, Najibabad, where a team of passionate educators is
						dedicated to nurturing young minds and fostering a love for
						learning. Our staff is committed to providing a supportive and
						enriching environment for every student. Get to know the faces
						behind the classrooms!
					</p>
					<button onClick={onButtonClick} className="staff-list btn btn-dark">
						Click here to view
					</button>
				</div>
			</div>
		</div>
	);
};
export default StaffList;
