import React, { useState } from "react";
import "../Styles/Transfer.css";
import download from "../Images/Download.png";
import { getDataFromCollectionWithQuery } from "../utils/helper";
import { COLLECTIONS } from "../utils/constants";

const Transfer = () => {
	const [inputs, setInputs] = useState({
		admissionNumber: "",
		dateOfBirth: "",
	});
	const [loading, setLoading] = useState(false);
	const [noDataFound, setNoDataFound] = useState(false);
	const [tcData, setTcData] = useState([]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInputs((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const searchData = async () => {
		try {
			setLoading(true);
			const data = await getDataFromCollectionWithQuery(
				COLLECTIONS.TRANSFER_CERTIFICATE,
				[
					{
						field: "admissionNumber",
						operator: "==",
						value: inputs.admissionNumber,
					},
					{
						field: "dob",
						operator: "==",
						value: inputs.dateOfBirth,
					},
				]
			);

			if (data.length === 0) {
				setTcData([]);
				setNoDataFound(true);
				return;
			}

			setTcData(data);
			setNoDataFound(false);
		} catch (error) {
			console.log("error in searchData", error);
		} finally {
			setLoading(false);
		}
	};

	const onSubmit = (e) => {
		e.preventDefault();
		searchData();
	};

	return (
		<div className="Transfer-info">
			<div className="transfer-certificate-form d-flex container mt-5">
				<div
					style={{
						width: "50%",
					}}
				>
					<h1 className="downloadTc mb-5">Transfer Certificate</h1>
					<form className="Transfer-details">
						<div className="form-group">
							<label htmlFor="number" className="mb-0">
								Admission Number
							</label>
							<input
								name="admissionNumber"
								type="text"
								value={inputs.admissionNumber}
								className="form-control"
								id="number"
								placeholder="Add Your Admission Number"
								onChange={handleChange}
							/>
							<div className="form-group dob">
								<div className="">
									<label htmlFor="date" className="mb-0">
										Date Of Birth
									</label>
								</div>
								<input
									name="dateOfBirth"
									type="date"
									id="date"
									value={inputs.dateOfBirth}
									placeholder="Add Your DOB"
									onChange={handleChange}
								/>
							</div>
							<button
								onClick={onSubmit}
								type="submit"
								className={"btn submit " + (loading ? "disabled" : "btn-dark")}
								disabled={loading}
							>
								{loading ? "Fetching data...." : "Get"}
							</button>
						</div>
					</form>
				</div>
			</div>

			{tcData.length > 0 && (
				<div className="container mt-5">
					<h3 className="transfer-data">Transfer Certificate</h3>
					<table className="transfer-certificate">
						<tbody>
							<tr className="details">
								<td width={350}>Student Name</td>
								<td width={350}>Date Of Birth</td>
								<td width={350}>Your TC</td>
							</tr>
							<tr className="details-about">
								<td>{tcData[0].name}</td>
								<td>{tcData[0].dob}</td>
								<td>
									<a href={tcData[0].pdf.url} target="_blank" rel="noreferrer">
										Download Your TC{" "}
										<img
											className="ml-2"
											src={download}
											alt="download"
											style={{
												width: "15px",
											}}
										/>
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			)}

			{noDataFound && (
				<div className="container mt-5">
					<h3 className="transfer-data">No Data Found</h3>
				</div>
			)}

			<div
				style={{
					marginBottom: "8%",
				}}
			/>
		</div>
	);
};

export default Transfer;
