import React from "react";
import Slider from "react-slick";

// Custom arrow components
const NextArrow = (props) => {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: "block", right: 45, top: "60%", zIndex: 1 }}
			onClick={onClick}
		/>
	);
};

const PrevArrow = (props) => {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: "block", left: 50, top: "60%", zIndex: 1 }}
			onClick={onClick}
		/>
	);
};

const GroupedResults = ({ groupedData }) => {
	const sliderSettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
	};

	const slides = [];

	Object.entries(groupedData).forEach(([year, classes]) => {
		Object.entries(classes).forEach(([className, streamsOrStudents]) => {
			if (className === "10") {
				slides.push(
					<div key={`${year}-${className}`} style={{ width: "100%" }}>
						<div className="Class">
							<h3 className="lastYear">Results {year}</h3>
							<h5 className="twelve">Class {className}</h5>
							<div className="toppers">
								<div className="row Student-Result-Data mr-0">
									{streamsOrStudents.students.map((student, index) => (
										<div key={student.id} className={`std${index + 1}`}>
											<img
												width={220}
												height={250}
												src={student.image}
												alt={student.name}
											/>
											<p className="stud">{student.name}</p>
											<p className="marks">{student.percentage}%</p>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				);
			} else if (className === "12") {
				Object.entries(streamsOrStudents).forEach(([stream, students]) => {
					slides.push(
						<div
							key={`${year}-${className}-${stream}`}
							style={{ width: "100%" }}
						>
							<div className="Class">
								<h3 className="lastYear">Results {year}</h3>
								<h5 className="twelve">Class {className}</h5>
								<div className="toppers">
									<div className="row Student-Result-Data mr-0">
										{students.map((student, index) => (
											<div key={student.id} className={`std${index + 1}`}>
												<img
													width={220}
													height={250}
													src={student.image}
													alt={student.name}
												/>
												<p className="stud">{student.name}</p>
												<p className="marks">
													{stream}: {student.percentage}%
												</p>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					);
				});
			}
		});
	});

	return <Slider {...sliderSettings}>{slides}</Slider>;
};

export default GroupedResults;
