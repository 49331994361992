import React, { useState } from "react";
import "../Styles/ContactUs.css";
import {
	addDataInDocument,
	showErrorToast,
	showSuccessToast,
} from "../utils/helper";
import { COLLECTIONS } from "../utils/constants";

const ContactUs = () => {
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [admissionClass, setAdmissionClass] = useState("");
	const [message, setMessage] = useState("");
	const [errors, setErrors] = useState({});

	const validate = () => {
		const errors = {};
		if (!name) errors.name = "Full Name is required";
		if (!phone) errors.phone = "Phone Number is required";
		else if (!/^\d{10}$/.test(phone))
			errors.phone = "Enter a valid 10-digit phone number";
		if (!admissionClass)
			errors.admissionClass = "Class for Admission is required";
		if (!message) errors.message = "Message is required";
		return errors;
	};

	const saveContactDetails = async () => {
		try {
			const preparedData = {
				name,
				phone,
				admissionClass,
				message,
			};
			await addDataInDocument(COLLECTIONS.CONTACTS, preparedData);
			alert("Your details have been submitted successfully");
			setName("");
			setPhone("");
			setAdmissionClass("");
			setMessage("");

			showSuccessToast("Contact details submitted successfully");
		} catch (error) {
			console.log("Error while saving contact details", error);
			showErrorToast("Error while submitting contact details");
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("Inside submit");
		const validationErrors = validate();
		console.log("Validation errors", validationErrors);
		if (Object.keys(validationErrors).length === 0) {
			setErrors({});
			saveContactDetails();
		} else {
			setErrors(validationErrors);
		}
	};

	return (
		<div className="contact-details">
			<div className="row mr-0">
				<div className="col-6 ">
					<h1 className="contact-heading">Contact Us</h1>
					<p className="para ">
						We value your inquiry and feedback. Our dedicated team is here to
						<br />
						assist you. Whether you have questions about our programs, <br />
						admissions, or any other aspect of our school, feel free to reach
						out.
					</p>
					<form className="form-container" onSubmit={handleSubmit}>
						<div className="form-group">
							<label htmlFor="name" className="mb-0">
								Full Name*
							</label>
							<input
								type="text"
								className="form-control"
								id="name"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							{errors.name && <div className="text-danger">{errors.name}</div>}

							<label className="mt-4 mb-0" htmlFor="phone">
								Enter Your 10 Digit Phone Number*
							</label>
							<input
								type="number"
								className="form-control"
								id="phone"
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
								maxLength={10}
							/>
							{errors.phone && (
								<div className="text-danger">{errors.phone}</div>
							)}

							<label className="mt-4 mb-0" htmlFor="admissionClass">
								Select Class For Admission *
							</label>
							<input
								type="text"
								className="form-control"
								id="admissionClass"
								value={admissionClass}
								onChange={(e) => setAdmissionClass(e.target.value)}
							/>
							{errors.admissionClass && (
								<div className="text-danger">{errors.admissionClass}</div>
							)}

							<label className="mt-4 mb-0" htmlFor="message">
								Message *
							</label>
							<textarea
								className="form-control"
								id="message"
								rows="5"
								value={message}
								onChange={(e) => setMessage(e.target.value)}
							/>
							{errors.message && (
								<div className="text-danger">{errors.message}</div>
							)}
						</div>

						<button type="submit" className="contact-btn">
							Submit
						</button>
					</form>
				</div>

				<div className="col-6 information">
					<div className="add">
						<h3>SCHOOL ADDRESS </h3>
						<p>Ajmal Khan Road, Najiababad, Distt Bijnour, Uttar Pradesh.</p>
					</div>
					<div className="num">
						<h3>SCHOOL PHONE NUMBER</h3>
						<p>+91 06455-224469</p>
					</div>
					<div className="mail">
						<h3>EMAIL ADDRESS</h3>
						<p>mpsnajibabad@gmail.com</p>
					</div>
					<div className="time">
						<h3>TIMING Monday - Friday:</h3>
						<p>7:30 AM to 3:30 PM</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactUs;
