import React, { useEffect, useState } from "react";
import HeaderBanner from "../Components/HeaderBanner";
import Footer from "../Components/Footer";
import Bg1 from "../Images/bg8.png";
import UprightArrow from "../Images/up-right.png";
import { getDataFromCollection } from "../utils/helper";
import { COLLECTIONS } from "../utils/constants";
import Documents from "../Components/Documents";

const CbseCircular = () => {
	const [listData, setListData] = useState([]);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		try {
			const data = await getDataFromCollection(COLLECTIONS.CBSE_CIRCULAR);
			setListData(data);
		} catch (error) {
			console.log("Error in getData", error);
		}
	};

	return (
		<div>
			<HeaderBanner
				bannerImage={Bg1}
				logoText={"Modern Public School"}
				subTitle={"CBSE Circular"}
				buttonLogo={UprightArrow}
			/>
			<Documents
				data={listData}
				description={`Stay informed with the latest updates and circulars issued by the Central Board of Secondary Education (CBSE) through our dedicated “CBSE Circulars” section. This space serves as a resource hub for parents, students, and faculty, providing quick access to important announcements, policy changes, and directives from CBSE.`}
			/>
			<Footer />
		</div>
	);
};
export default CbseCircular;
