export const PAGE_ROUTES = {
	HOME: "/",
	SUBJECTS_OFFERED: "/subjects-offered",
	INQUIRIES: "/inquiries",
	ISSUE_OF_TC: "/issue-of-tc",
	FEES_STRUCTURE: "/fees-structure",
	MANDATORY_DISCLOSURE: "/mandatory-disclosure",
	CBSE_CIRCULAR: "/cbse-circular",
	STAFF_LIST: "/staff-list",
	LOGIN: "/login",
};

export const CLASS_DATA = [
	{
		value: "10",
		label: "10th",
	},
	{
		value: "12",
		label: "12th",
	},
];

export const STREAM_DATA = [
	{
		value: "science",
		label: "Science",
	},
	{
		value: "commerce",
		label: "Commerce",
	},
	{
		value: "arts",
		label: "Arts",
	},
];

export const ALL_CLASSES_DATA = [
	{ label: "Nursery", value: "Nursery" },
	{ label: "Junior Kg", value: "Junior Kg" },
	{ label: "Senior Kg", value: "Senior Kg" },
	{ label: "I", value: "I" },
	{ label: "II", value: "II" },
	{ label: "III", value: "III" },
	{ label: "IV", value: "IV" },
	{ label: "V", value: "V" },
	{ label: "VI", value: "VI" },
	{ label: "VII", value: "VII" },
	{ label: "VIII", value: "VIII" },
	{ label: "IX", value: "IX" },
	{ label: "X", value: "X" },
	{ label: "XI Commerce", value: "XI Commerce" },
	{ label: "XI Science", value: "XI Science" },
	{ label: "XII Commerce", value: "XII Commerce" },
	{ label: "XII Science", value: "XII Science" },
];

export const COLLECTIONS = {
	RANK: "rank",
	SUBJECTS: "subjects",
	FEES: "fees",
	TRANSFER_CERTIFICATE: "transfer_certificate",
	MANDATORY_DISCLOSURE: "mandatory_disclosure",
	CBSE_CIRCULAR: "cbse_circular",
	STAFF_LIST: "staff_list",
	CONTACTS: "contacts",
	FRC_ORDER: "frc_order",
	INQUIRIES: "inquiries",
};

export const TEXTS = {
	AFFILIATION_NUMBER: "2130352",
};
