import React from "react";
import "../Styles/Lc.css";
import { Link } from "react-router-dom";

const Lc = () => {
	return (
		<div className="container">
			<div className="row mr-0">
				<div className="col Lc-Tc">
					<p>
						<p>
							1. T.C/L.C. will be issued after 7 days from the date of
							submission of the application in the prescribed form.
							<Link to="/transferCertificate">(Click here to download)</Link>
							<br />
						</p>
						<p>
							2. Application for TC/LC in the prescribed form can be submitted
							by email or at the School admission office. <br />
						</p>
						<p>
							3. For obtaining TC/LC, the Parent/Guardian should first clear all
							dues of Academic and Non Academic departments ( Fee, Library,
							Sports, Labs, Transport etc)
							<br />
						</p>
						<p>
							4. School fees rules shall be applicable as mentioned in the
							School Almanac. <br />
						</p>
						<p>
							5. A copy of cancelled cheque is to be submitted along with the
							TC/LC application form. Caution money will be deposited in the
							Parent/Guardian’s bank Account for which they have submitted the
							copy of cancelled cheque. <br />
						</p>
						<p>
							6. Caution money will be deposited within 15 days from the issue
							of the original TC. <br />
						</p>
						<p>
							7. A duplicate or triplicate copy of T.C. will be issued only
							after an affidavit and newspaper cutting is submitted informing us
							that the T.C. is lost /damaged. <br />
						</p>
						<p>
							8. No TC/LC will be issued after the issue of the triplicate T.C.{" "}
							<br />
						</p>
						<p>
							9. A Fee of Rs. 300/- will be charged for a duplicate TC and a Fee
							of Rs 500/- will be charged for triplicate T.C. and this will be
							issued only three days after the receipt of the withdrawal form
							duly signed by the Student/Guardian. <br />
						</p>
						<p>
							10. An application is to be given one month in advance if any
							assessment report is required in case of mid-term withdrawal.{" "}
							<br />
						</p>
						<p>
							11. In case of admission/ withdrawal anytime during the session,
							the school fees for that particular quarter will be settled/not to
							be refunded as the case may be. <br />
						</p>
						<p>
							12. Transfer Certificates will not be issued until all dues of the
							school are settled. <br />
						</p>
						<p>
							13. As per CBSE guidelines, TC will be published online and parent
							can view the TC on school’s official website.
							<Link to="/transferCertificate">Click to view</Link>
						</p>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Lc;
