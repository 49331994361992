import React, { useCallback, useEffect, useState } from "react";
import "../Styles/Academics.css";

import Accordion from "./Accordion";
import { getDataFromCollection } from "../utils/helper";
import { COLLECTIONS } from "../utils/constants";

const Academics = () => {
	const [accordionData, setAccordionData] = useState([]);

	const getData = useCallback(async () => {
		try {
			const data = await getDataFromCollection(COLLECTIONS.SUBJECTS);
			setAccordionData(data);
		} catch (error) {
			console.log("error in getting data", error);
		}
	}, []);

	useEffect(() => {
		getData();
	}, [getData]);

	return (
		<div className="Academics">
			<div className="">
				<div className="offered">
					<h1>Subjects offered V-XII ( 2024-25 )</h1>
				</div>
				<div className="">
					<div className="intro mb-3 ">
						MPS-Najibabad is a learning institution that promotes innovation,
						academic excellence, and intellectual curiosity. Through a well-
						structured CBSE curriculum in consonance with NEP 2020, we instill a
						strong foundation of knowledge, critical thinking, problem-solving
						skills, and creativity in students.
					</div>
					<h6 className="methods mb-3 ">OUR TEACHING METHODOLOGY</h6>
					<div className="tech mb-3">
						The school believes in using the latest technology for imparting the
						knowledge through experienced faculty. The school is pioneer in the
						city to introduce the latest technology , which includes:
					</div>
					<div className="info mb-4">
						<br /> • Video conferencing to get the advantage of best oustation
						teachers throughout India <div className="br"></div> • Audio visual
						lectures with the help of LCD projectors & screen which create
						lasting impact on the child’s memory. <br /> • Online educational
						software and E-learning modules a teaching aid. <br /> <br />
						<div className="identification mb-4">
							We identify unique learning abilities of each student and impart
							customized education based on the individual needs. The school
							organizes various intra and inter school competitions to ilitate
							and foster healthy competitive spirit among the students. <br />
						</div>
					</div>
					<div className="learning mb-4">
						We provide multi-level, multi-content and multi-sensory learning
						experiences to our kindergarten students. We believe in Play-Way
						teaching methodology for kindergarten. It serves the need and
						purpose of naturing a “whole child” and every child along with
						values of honesty , integrity and arduousness.
						<h5 className="socialize mt-3 col-12">
							“We care for your child and teach him the way he understands”
						</h5>
					</div>
				</div>
			</div>
			<div className="std">
				<Accordion data={accordionData} />
			</div>
		</div>
	);
};
export default Academics;
