import React from "react";
import HeaderBanner from "../Components/HeaderBanner";
import Footer from "../Components/Footer";
import Bg1 from "../Images/bg7.png";
import UprightArrow from "../Images/up-right.png";
import ContactUs from "../Components/ContactUs";

const Contact = () => {
	return (
		<div>
			<HeaderBanner
				bannerImage={Bg1}
				logoText={"Modern Public School"}
				subTitle={"Contact Us"}
				buttonLogo={UprightArrow}
			/>
			<ContactUs />
			<Footer />
		</div>
	);
};
export default Contact;
