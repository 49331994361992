import React from "react";
import HeaderBanner from "../Components/HeaderBanner";
import Footer from "../Components/Footer";
import Bg1 from "../Images/bg5.png";
import UprightArrow from "../Images/up-right.png";
import Transfer from "../Components/Transfer";

const TransferCertificate = () => {
	return (
		<div>
			<HeaderBanner
				bannerImage={Bg1}
				logoText={"Modern Public School"}
				subTitle={"Download Transfer Certificate"}
				buttonLogo={UprightArrow}
			/>
			<Transfer />
			<Footer />
		</div>
	);
};
export default TransferCertificate;
