import React from "react";
import HeaderBanner from "../Components/HeaderBanner";
import Footer from "../Components/Footer";
import Bg1 from "../Images/bg9.png";
import UprightArrow from "../Images/up-right.png";
import Fees from "../Components/Fees";

const FeeStructure = () => {
	return (
		<div>
			<HeaderBanner
				bannerImage={Bg1}
				logoText={"Modern Public School"}
				subTitle={"Fees Structure"}
				buttonLogo={UprightArrow}
			/>
			<Fees />
			<Footer />
		</div>
	);
};
export default FeeStructure;
