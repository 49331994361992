import React from "react";
import HeaderBanner from "../Components/HeaderBanner";
import Bg3 from "../Images/bg4.png";
import UprightArrow from "../Images/up-right.png";
import Footer from "../Components/Footer";
import Academics from "../Components/Academics";

const SubjectOffered = () => {
	return (
		<div>
			<HeaderBanner
				bannerImage={Bg3}
				logoText={"Modern Public School"}
				subTitle={"Subject Offered "}
				buttonText={`Apply For Admission`}
				buttonLogo={UprightArrow}
			/>

			<Academics />

			<Footer />
		</div>
	);
};

export default SubjectOffered;
