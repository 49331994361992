import React from "react";
import "../Styles/Admission.css";
import img1 from "../Images/process.png";
import { useNavigate } from "react-router-dom";

const Admission = () => {
	const navigate = useNavigate();
	return (
		<div className="procedure-Data d-flex justify-between">
			<div
				className="Procedure-Steps pl-5 mt-5"
				style={{
					width: "100%",
				}}
			>
				<h2 className="procedure">Steps for Admission Procedure:</h2>
				<div className="steps d-flex flex-column">
					<p className="first">
						1.Application form is available online or visiting school admission
						office on the basis of vacancy available in respective classes.
					</p>
					<p className="second">
						2.Application form has to be filled online under the admission link
						on the website.
					</p>
					<p className="third">
						3.On successfully submitting the Application form, school authority
						will get in touch with you.
					</p>
					<p className="forth">
						4.Parents must visit the school office as acknowledged by school
						authority.
					</p>
					<p className="fifth">
						5.Payment of admission application form charges does not guarantee
						an admission seat.
					</p>
					<p className="sixth">
						6.Once the Admission selection is made, parents will be notified
						with a confirmation via both Email & SMS.
					</p>
					<p className="seventh">
						7.After confirmation of admission, Parents are required to complete
						the Enrollment formalities by remitting the “Amount payable at the
						time of admission”.
					</p>
					<p className="eighth">
						8.Amount payable at the time of admission confirmation has to be
						deposited through cash or online payment i.e. Netbanking /Debit
						Card.
					</p>
					<div
						className=" btn-primary Register-Inquiry"
						onClick={() => navigate("/inquiry")}
						style={{
							cursor: "pointer",
						}}
					>
						Register For Inquiry
					</div>
				</div>
			</div>
			<div
				className="process-image"
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<img
					src={img1}
					alt="Process of Admission"
					style={{
						width: "50%",
					}}
				/>
			</div>
		</div>
	);
};
export default Admission;
