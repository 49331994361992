import React from "react";
import "../Styles/Documents.css";

const Documents = ({ data, description }) => {
	return (
		<div className="container mt-5">
			<div className="row mr-0">
				<div className="col">
					<p className="latestUpdates">{description}</p>
					{data.length > 0 && (
						<div>
							<h4 className="about">DOCUMENTS</h4>
							<table className="table table-bordered">
								<thead>
									<tr>
										<th>SL No</th>
										<th>DOCUMENTS/INFORMATION</th>
										<th>DETAILS</th>
									</tr>
								</thead>
								<tbody>
									{data.map((item, index) => (
										<tr key={item.id}>
											<td>{index + 1}</td>
											<td>{item?.documentName}</td>
											<td>
												<a href={item.pdf.url} target="_blank" rel="noreferrer">
													CLICK HERE
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
export default Documents;
