import React from "react";
import "../Styles/footer.css";
import img1 from "../Images/logo1.png";
import img2 from "../Images/call.png";
import img3 from "../Images/mail.png";
import img4 from "../Images/location.png";
import img5 from "../Images/fac.png";
import img6 from "../Images/insta.png";
import img7 from "../Images/twit.png";
import img8 from "../Images/in.png";
import { TEXTS } from "../utils/constants";

const Footer = () => {
	return (
		<footer>
			<div className="position-relative">
				<div className="footer1" />
				<div className="container sub-footer">
					<div className="row mr-0">
						<div className="col-12 d-flex flex-column align-items-center">
							<h2
								style={{
									fontSize: "3rem",
									marginBottom: "2rem",
								}}
							>
								Best CBSE School in Najibabad{" "}
							</h2>
							<p
								style={{
									color: "#fff",
									fontStyle: "italic",
									fontSize: "1.5rem",
									width: "65%",
									textAlign: "center",
								}}
							>
								"Education is the bridge that connects dreams with reality,
								turning potential into progress and learners into leaders."
							</p>
							<button
								className="btn contact"
								onClick={(e) => {
									e.preventDefault();
									window.location.href = "/Contact";
								}}
							>
								Contact Us
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className="footer2">
				<img src={img1} alt="" />
				<div className="container">
					<div className="row school-links mr-0">
						<div className="col Admission">
							<h5>Admissions</h5>
							<ul>
								<li>
									<a href="/admissionProcedure">Admission Procedure</a>
								</li>
								<li>
									<a href="/inquiry">Register For Inquiry</a>
								</li>
								<li>
									<a href="/lcTC">Procedure for TC-LC</a>
								</li>
								<li>
									<a href="/transferCertificate">
										Download Transfer Certificate
									</a>
								</li>

								<li>
									<a href="/feeStructure">Fees Structure</a>
								</li>
							</ul>
						</div>
						<div className="col">
							<h5>CBSE</h5>
							<ul>
								<li>
									<a href="/mandatoryDisclosure">Mandatory Disclosure</a>
								</li>
								<li>
									<a href="/cbseCircular">CBSE Circular</a>
								</li>
								<li>
									<a href="/Contact">Contact</a>
								</li>
								<li>
									<a href="/StaffDetails">Staff List</a>
								</li>
							</ul>
						</div>
						<div className="col Contact">
							<h5>Contact Us</h5>
							<ul>
								<li className="phone-school">
									<img src={img2} alt="" />
									<a href="tel:+9106455224469">+91 06455-224469</a>
								</li>
								<li className="Email-school">
									<img src={img3} alt="" />
									<a href="mailto:mpsnajibabad@gmail.com">
										mpsnajibabad@gmail.com
									</a>
								</li>

								<li className="Address-School">
									<img src={img4} alt="" />

									<a href="/">
										Ajmal Khan Road, Najiababad, Distt Bijnour, Uttar Pradesh.
									</a>
								</li>
							</ul>
							<ul></ul>
						</div>
					</div>
				</div>
			</div>

			<div className="footer3">
				<div className=" CBSE">
					<div className="">
						CBSE Affiliation Number : {TEXTS.AFFILIATION_NUMBER}
					</div>
				</div>
			</div>

			<div className="footer4">
				<div className="copyRight">
					<p>
						Copyright © 2024 Modern Public School, Najibabad.  All rights
						reserved.
					</p>
				</div>
				<div className="apps ">
					<img src={img5} alt="" />
					<img src={img7} alt="" />
					<img src={img8} alt="" />
					<img src={img6} alt="" />
				</div>
			</div>
		</footer>
	);
};

export default Footer;
