import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyDJc5Zcw9qJv_MTAPD9FsctHkAWpoKMuuo",
	authDomain: "modern-school-web.firebaseapp.com",
	projectId: "modern-school-web",
	storageBucket: "modern-school-web.appspot.com",
	messagingSenderId: "1039391467628",
	appId: "1:1039391467628:web:cc7af33cc744490b6b1049",
};

const app = initializeApp(firebaseConfig);

export const firebaseStorage = getStorage(app);
export const db = getFirestore(app);
