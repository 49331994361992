import React from "react";
import HeaderBanner from "../Components/HeaderBanner";
import Footer from "../Components/Footer";
import Lc from "../Components/Lc";
import Bg1 from "../Images/bg5.png";
import UprightArrow from "../Images/up-right.png";

const LcTC = () => {
	return (
		<div>
			<HeaderBanner
				bannerImage={Bg1}
				logoText={"Modern Public School"}
				subTitle={"Procedure for TC-LC"}
				buttonLogo={UprightArrow}
			/>
			<Lc />
			<Footer />
		</div>
	);
};
export default LcTC;
