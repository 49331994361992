import React from "react";
import "../Styles/Images.css";
import img1 from "../Images/pre-primary1.png";
import img2 from "../Images/pre-primary2.png";
import img3 from "../Images/pre-primary3.png";
import img4 from "../Images/auditorium.png";
import img5 from "../Images/auditorium2.png";
import img6 from "../Images/auditorium3.png";
import img7 from "../Images/chemistry.png";
import img8 from "../Images/sports.png";
import img9 from "../Images/library.png";

const Images = () => {
	return (
		<div className=" images">
			<div className="Audi-Primary">
				<h5 className="mt-5 mb-0 prePrimaryHeading ">Pre-Primary Classes</h5>
				<div className="row pre-primary mr-0">
					<div className="col-4">
						<img src={img1} alt="" />
					</div>
					<div className="col-4">
						<img src={img2} alt="" />
					</div>
					<div className="col-4">
						<img src={img3} alt="" />
					</div>
				</div>
				<h5 className="auditoriumHeading">Auditorium</h5>
				<div className="row auditorium mr-0">
					<div className="col-4">
						<img src={img4} alt="" />
					</div>
					<div className="col-4">
						<img src={img5} alt="" />
					</div>
					<div className="col-4">
						<img src={img6} alt="" />
					</div>
				</div>
			</div>
			<h5 className="chemistryBioHeading">Chemistry & Bio Lab</h5>
			<div className="row mr-0 pl-3">
				<div className="col-4 Lab">
					<img src={img7} alt="" />
				</div>

				<div className="col-8 Sports">
					<h5 className="sportsHeading">Sports Ground</h5>
					<br />
					<img src={img8} alt="" />
				</div>
			</div>
			<div className="col-12 Library">
				<h5 className="LibraryHeading">Library</h5>
				<img src={img9} alt="" />
			</div>
		</div>
	);
};

export default Images;
