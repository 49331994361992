import React from "react";
import HeaderBanner from "../Components/HeaderBanner";
import Footer from "../Components/Footer";
import Admission from "../Components/Admission";
import Bg1 from "../Images/bg1.png";
import UprightArrow from "../Images/up-right.png";

const AdmissionProcedure = () => {
	return (
		<div>
			<HeaderBanner
				bannerImage={Bg1}
				logoText={"Modern Public School"}
				singleLine1={""}
				bannerTitle1={""}
				subTitle={"Admission Procedure "}
				buttonText={`Apply For Admission`}
				buttonLogo={UprightArrow}
			/>
			<Admission />
			<Footer />
		</div>
	);
};

export default AdmissionProcedure;
