import React, { useState } from "react";
import "../Styles/AdmissionForm.css";
import { ALL_CLASSES_DATA, COLLECTIONS } from "../utils/constants";
import {
	addDataInDocument,
	showErrorToast,
	showSuccessToast,
} from "../utils/helper";

const AdmissionForm = () => {
	const [name, setName] = useState("");
	const [birthDate, setBirthDate] = useState("");
	const [selectedClass, setSelectedClass] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [errors, setErrors] = useState({});

	const validate = () => {
		const newErrors = {};
		if (!name) newErrors.name = "Child's name is required";
		if (!birthDate) newErrors.birthDate = "Date of birth is required";
		if (!selectedClass) newErrors.selectedClass = "Class selection is required";
		if (!phoneNumber) {
			newErrors.phoneNumber = "Phone number is required";
		} else if (!/^\d{10}$/.test(phoneNumber)) {
			newErrors.phoneNumber = "Phone number must be 10 digits";
		}
		if (!email) {
			newErrors.email = "Email is required";
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			newErrors.email = "Email is invalid";
		}
		return newErrors;
	};

	const handleSave = async () => {
		try {
			const preparedData = {
				name: name,
				dob: birthDate,
				class: selectedClass,
				phone: phoneNumber,
				email: email,
			};

			await addDataInDocument(COLLECTIONS.INQUIRIES, preparedData);

			setName("");
			setBirthDate("");
			setSelectedClass("");
			setPhoneNumber("");
			setEmail("");

			showSuccessToast("Inquiry submitted successfully");
		} catch (error) {
			console.log("Error in save: ", error);
			showErrorToast("Error in submitting inquiry");
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const formErrors = validate();
		if (Object.keys(formErrors).length === 0) {
			setErrors({});
			handleSave();
		} else {
			setErrors(formErrors);
		}
	};

	return (
		<div className="Inquiry-registration">
			<div>
				<div className=" Inquiry-Data mr-0 ">
					<h1 className="inquiry-heading">Inquire to register for Admission</h1>
					<p className="para">
						Unleash the full potential of your aspirations and future endeavors
						<br /> by commencing your academic voyage at Modern Public School-
						<br /> Najibabad, renowned as the best school in Najibabad.
					</p>
					<form className="form-container2" onSubmit={handleSubmit}>
						<div className="form-group">
							<label htmlFor="name" className="mb-0">
								Enter Your Child Name*
							</label>
							<input
								type="text"
								className="form-control"
								id="name"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							{errors.name && <div className="text-danger">{errors.name}</div>}

							<label htmlFor="birthDate" className="mt-4 mb-0">
								Enter Your Child's Date Of Birth*
							</label>
							<input
								type="date"
								className="form-control"
								id="birthDate"
								value={birthDate}
								onChange={(e) => setBirthDate(e.target.value)}
							/>
							{errors.birthDate && (
								<div className="text-danger">{errors.birthDate}</div>
							)}

							<label className="mt-4 mb-0" htmlFor="streams">
								Class *
							</label>
							<select
								className="form-control"
								id="streams"
								value={selectedClass}
								onChange={(e) => setSelectedClass(e.target.value)}
							>
								<option value="">Select Class</option>
								{ALL_CLASSES_DATA.map((item, index) => (
									<option key={index} value={item.value}>
										{item.label}
									</option>
								))}
							</select>
							{errors.selectedClass && (
								<div className="text-danger">{errors.selectedClass}</div>
							)}

							<label className="mt-4 mb-0" htmlFor="phoneNumber">
								Enter Your 10 Digit Phone Number*
							</label>
							<input
								type="text"
								className="form-control"
								id="phoneNumber"
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
							/>
							{errors.phoneNumber && (
								<div className="text-danger">{errors.phoneNumber}</div>
							)}

							<label className="mt-4 mb-0" htmlFor="email">
								Enter Your E-Mail ID*
							</label>
							<input
								type="email"
								className="form-control"
								id="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							{errors.email && (
								<div className="text-danger">{errors.email}</div>
							)}
						</div>
						<button type="submit" className="Inquiry-submit">
							Submit
						</button>
					</form>
				</div>
				<div className="information2">
					<div className="add">
						<h3>SCHOOL ADDRESS </h3>
						<p>
							Ajmal Khan Road, Najiababad, <br /> Distt Bijnour, Uttar Pradesh.
						</p>
					</div>
					<div className="add">
						<h3>SCHOOL PHONE NUMBER</h3>
						<p>+91 06455-224469</p>
					</div>
					<div className="add">
						<h3>EMAIL ADDRESS</h3>
						<p>mpsnajibabad@gmail.com</p>
					</div>
					<div className="add">
						<h3>TIMING Monday - Friday:</h3>
						<p>7:30 AM to 3:30 PM</p>
					</div>
				</div>
			</div>
		</div>
	);
};
export default AdmissionForm;
